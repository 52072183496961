.chat-view-container {
    display: flex !important;
    flex-direction: column;
    height: 85vh;
    overflow-y: auto;
  }
  .chat-header{
    display: flex;
    justify-content:flex-start;
    align-items: center;
    padding: 10px;
    background-color: #f7e165!important;
    border-bottom: 1px solid #e5e5e5;
    
  }
  .messages-container {
    display: flex !important;
    flex-direction: column;
    max-height: 'calc(10vh - 100px)';
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;

  }
  .message {
    max-width: 70%;
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .admin {
    align-self: flex-end;
    background-color: #faeb97 !important;
    border-bottom-right-radius: 0;
  }
  
  .user {
    align-self: flex-start;
    background-color: #F5F7F8 !important;
    border-bottom-left-radius: 0;
  }
  
  .message-image {
    margin-top: 10px;
    max-width: 100%;
    border-radius: 10px;
  }
  
  .reported-image {
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
  }
  
  .message-form {
    display: block;
    margin: 10px 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .input-container {
    display: flex;
    align-items: center;
    margin: 10px;
    
  }
  .message-input {
    overflow-wrap: break-word;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    max-width: 100%;
    width: 92%;
    resize: none; /* Disable manual resizing */
    overflow: hidden; /* Hide scrollbars */
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  }
  
  
  .file-input {
    margin-bottom: 10px;
  }
  
  .send-button {
    padding: 10px;
    background-color: #f7e165;
    color: black;
    border: none;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .send-button:hover {
    background-color: #f7e165;
  }
  